
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

export class AutoCompleteDataSourceResult {
  DataObject: any;
  DisplayText: string;
  Value: any;

  constructor(data: any, display_text: string, value: any) {
    this.DataObject = data;
    this.DisplayText = display_text;
    this.Value = value;
  }
}

export interface IAutoCompleteDataSource {
  Query(search: string): Promise<AutoCompleteDataSourceResult[]>;
  GetByValue(value: any): Promise<AutoCompleteDataSourceResult | null>;
}

@Component
export default class VMSAutoComplete extends Vue {
  @Prop() value: any | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(String) searchInput: string | undefined;
  @Prop(Object) dataSource: IAutoCompleteDataSource | undefined;

  selected_item: AutoCompleteDataSourceResult | null = null;
  search: string = "";
  error: boolean = false;

  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;

  items: AutoCompleteDataSourceResult[] = [];
  loading: boolean = false;

  @Watch("value")
  onValueChanged() {
    this.loadValue();
  }

  @Watch("search")
  onSearchChanged() {
    this.$emit("update:search-input", this.search);

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getData, this.typeTimer);
  }

  mounted() {
    (this.$refs.inRef as any).reset();
    this.search = this.searchInput || "";

    this.loadValue();
  }

  loadValue() {
    if (this.items.find(r => r.Value === this.value)) {
      return;
    }

    if (this.value && this.dataSource) {
      this.items = [];
      this.loading = true;
      this.dataSource
        .GetByValue(this.value)
        .then(resp => {
          if (resp) {
            this.items.push(resp);
            this.selected_item = resp;
          }
        })
        .finally(() => (this.loading = false));
    }
  }

  onInput() {
    this.error = false;
    if (!this.selected_item) {
      this.$emit("input", null);
      this.$emit("input-data", null);
    } else {
      this.$emit("input", this.selected_item.Value);
      this.$emit("input-data", this.selected_item.DataObject);
    }
  }

  onBlur() {
    this.error = !(this.$refs.inRef as any).validate();
  }

  Clear() {
    (this.$refs.vAutoComplete as any).reset();
  }

  getData() {
    if (this.search && this.search.length > 2 && this.dataSource) {
      this.loading = true;
      this.dataSource
        .Query(this.search)
        .then(resp => {
          this.items = resp;
        })
        .finally(() => (this.loading = false));
    }
  }
}
