var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("VolunteerProfile")))]),_c('v-form',{ref:"ProfileForm"},[_c('VMSSelect',{attrs:{"label":`${_vm.$t('CurrentlyStudent')}`,"items":_vm.YesNo,"rules":[
        () => _vm.student.length > 0 || `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},on:{"input":_vm.onCurrentlyStudentInput},model:{value:(_vm.student),callback:function ($$v) {_vm.student=$$v},expression:"student"}}),(_vm.student === 'Yes' && _vm.profile.StudentProfile)?_c('v-card',{staticClass:"pa-4 mb-4"},[_c('VMSAutoComplete',{attrs:{"label":_vm.$t('PostSecondaryInstitution'),"placeholder":_vm.$t('AutocompletePlaceholderText'),"rules":[
          () =>
            (_vm.profile.StudentProfile &&
              _vm.profile.StudentProfile.PostSecondaryInstitutionId != '') ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ],"data-source":_vm.org_data_source},model:{value:(_vm.profile.StudentProfile.PostSecondaryInstitutionId),callback:function ($$v) {_vm.$set(_vm.profile.StudentProfile, "PostSecondaryInstitutionId", $$v)},expression:"profile.StudentProfile.PostSecondaryInstitutionId"}}),_c('EnumerationSelect',{attrs:{"label":_vm.$t('LevelOfStudy'),"list":_vm.levelsOfStudyEnumList,"rules":[
          () =>
            (_vm.profile.StudentProfile &&
              _vm.profile.StudentProfile.LevelOfStudyId !== _vm.EmptyGuid) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.profile.StudentProfile.LevelOfStudyId),callback:function ($$v) {_vm.$set(_vm.profile.StudentProfile, "LevelOfStudyId", $$v)},expression:"profile.StudentProfile.LevelOfStudyId"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('YourFaculty'),"rules":[
          () =>
            (_vm.profile.StudentProfile &&
              _vm.profile.StudentProfile.Faculty.length > 0) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.profile.StudentProfile.Faculty),callback:function ($$v) {_vm.$set(_vm.profile.StudentProfile, "Faculty", $$v)},expression:"profile.StudentProfile.Faculty"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('YourDepartment'),"rules":[
          () =>
            (_vm.profile.StudentProfile &&
              _vm.profile.StudentProfile.Department.length > 0) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.profile.StudentProfile.Department),callback:function ($$v) {_vm.$set(_vm.profile.StudentProfile, "Department", $$v)},expression:"profile.StudentProfile.Department"}})],1):_vm._e(),(_vm.student === 'No' && _vm.profile.NonStudentProfile)?_c('v-card',{staticClass:"pa-4 mb-4"},[_c('SiteResponse',{attrs:{"label":_vm.$t('PreviousSite'),"multiple":true},model:{value:(_vm.profile.NonStudentProfile.SiteResponseHeader),callback:function ($$v) {_vm.$set(_vm.profile.NonStudentProfile, "SiteResponseHeader", $$v)},expression:"profile.NonStudentProfile.SiteResponseHeader"}}),_c('EnumerationSelect',{attrs:{"label":_vm.$t('WorkSituation'),"list":_vm.workSituationsEnumList,"rules":[
          () =>
            (_vm.profile.NonStudentProfile &&
              _vm.profile.NonStudentProfile.WorkSituationId !== _vm.EmptyGuid) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.profile.NonStudentProfile.WorkSituationId),callback:function ($$v) {_vm.$set(_vm.profile.NonStudentProfile, "WorkSituationId", $$v)},expression:"profile.NonStudentProfile.WorkSituationId"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('Employer')},model:{value:(_vm.profile.NonStudentProfile.Employer),callback:function ($$v) {_vm.$set(_vm.profile.NonStudentProfile, "Employer", $$v)},expression:"profile.NonStudentProfile.Employer"}})],1):_vm._e(),_c('EnumerationResponse',{attrs:{"label":_vm.$t('InterestedOpportunities'),"list":_vm.interestedOpportunitiesEnumList,"multiple":true,"rules":[
        () =>
          _vm.profile.InterestedOpportunities.Items.length > 0 ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.InterestedOpportunities),callback:function ($$v) {_vm.$set(_vm.profile, "InterestedOpportunities", $$v)},expression:"profile.InterestedOpportunities"}}),_c('EnumerationSelect',{attrs:{"label":_vm.$t('LanguagePreference'),"list":_vm.languagesEnumList,"rules":[
        () =>
          _vm.profile.LanguagePreferenceId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.LanguagePreferenceId),callback:function ($$v) {_vm.$set(_vm.profile, "LanguagePreferenceId", $$v)},expression:"profile.LanguagePreferenceId"}}),_c('EnumerationResponse',{attrs:{"label":_vm.$t('DeliveryLanguages'),"multiple":true,"list":_vm.languagesEnumList,"rules":[
        () =>
          _vm.profile.DeliveryLanguages.Items.length > 0 ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.DeliveryLanguages),callback:function ($$v) {_vm.$set(_vm.profile, "DeliveryLanguages", $$v)},expression:"profile.DeliveryLanguages"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('YourHobbies')},model:{value:(_vm.profile.Hobbies),callback:function ($$v) {_vm.$set(_vm.profile, "Hobbies", $$v)},expression:"profile.Hobbies"}}),_c('EnumerationSelect',{attrs:{"label":_vm.$t('PronounPreference'),"list":_vm.pronounsEnumList,"rules":[
        () =>
          _vm.profile.PronounPreferenceId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},on:{"conditionaltrigger":_vm.onPronounConditional},model:{value:(_vm.profile.PronounPreferenceId),callback:function ($$v) {_vm.$set(_vm.profile, "PronounPreferenceId", $$v)},expression:"profile.PronounPreferenceId"}}),(_vm.pronounConditional)?_c('VMSTextField',{attrs:{"label":_vm.$t('CustomPronouns'),"rules":[
        _vm.profile.CustomPronoun.length > 0 || `${_vm.$t('FieldIsRequired')}`
      ]},model:{value:(_vm.profile.CustomPronoun),callback:function ($$v) {_vm.$set(_vm.profile, "CustomPronoun", $$v)},expression:"profile.CustomPronoun"}}):_vm._e(),_c('v-card',{staticClass:"pa-4 mb-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("ProfileIdentityBlurb"))+" ")]),_c('EnumerationSelect',{attrs:{"label":_vm.$t('GenderIdentity'),"list":_vm.genderEnumList,"rules":[
          () =>
            _vm.profile.GenderIdentityId !== _vm.EmptyGuid ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},on:{"conditionaltrigger":_vm.onIdentityConditional},model:{value:(_vm.profile.GenderIdentityId),callback:function ($$v) {_vm.$set(_vm.profile, "GenderIdentityId", $$v)},expression:"profile.GenderIdentityId"}}),(_vm.genderConditional)?_c('VMSTextField',{attrs:{"label":_vm.$t('CustomGenderIdentity'),"rules":[
          _vm.profile.CustomGenderIdentity.length > 0 ||
            `${_vm.$t('FieldIsRequired')}`
        ]},model:{value:(_vm.profile.CustomGenderIdentity),callback:function ($$v) {_vm.$set(_vm.profile, "CustomGenderIdentity", $$v)},expression:"profile.CustomGenderIdentity"}}):_vm._e(),_c('EnumerationResponse',{attrs:{"label":_vm.$t('EthnicDescriptors'),"multiple":true,"list":_vm.ethnicDescriptorsEnumList,"rules":[
          () =>
            _vm.profile.EthnicDescriptors.Items.length > 0 ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.profile.EthnicDescriptors),callback:function ($$v) {_vm.$set(_vm.profile, "EthnicDescriptors", $$v)},expression:"profile.EthnicDescriptors"}})],1),_c('EnumerationSelect',{attrs:{"label":_vm.$t('CurrentProvince'),"list":_vm.provincesEnumList,"rules":[
        () =>
          _vm.profile.ProvinceId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.ProvinceId),callback:function ($$v) {_vm.$set(_vm.profile, "ProvinceId", $$v)},expression:"profile.ProvinceId"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('CurrentCity'),"rules":[
        () => _vm.profile.City.length > 0 || `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.City),callback:function ($$v) {_vm.$set(_vm.profile, "City", $$v)},expression:"profile.City"}}),_c('PhoneNumberInput',{attrs:{"label":_vm.$t('PrimaryContactPhoneNumber'),"rules":[
        () =>
          _vm.profile.PhoneNumber.length > 0 ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.PhoneNumber),callback:function ($$v) {_vm.$set(_vm.profile, "PhoneNumber", $$v)},expression:"profile.PhoneNumber"}}),_c('EnumerationResponse',{attrs:{"label":_vm.$t('AreaOfStudyOrExpertise'),"list":_vm.areasOfStudyEnumList,"multiple":true,"rules":[
        () =>
          _vm.profile.AreasOfStudyOrExpertise.Items.length > 0 ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.AreasOfStudyOrExpertise),callback:function ($$v) {_vm.$set(_vm.profile, "AreasOfStudyOrExpertise", $$v)},expression:"profile.AreasOfStudyOrExpertise"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('HeardAboutLTS')},model:{value:(_vm.profile.HeardAboutLTS),callback:function ($$v) {_vm.$set(_vm.profile, "HeardAboutLTS", $$v)},expression:"profile.HeardAboutLTS"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('WorkWithKidsExperience')},model:{value:(_vm.profile.WorkWithKidsExperience),callback:function ($$v) {_vm.$set(_vm.profile, "WorkWithKidsExperience", $$v)},expression:"profile.WorkWithKidsExperience"}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("Over18Hint"))+" ")]),_c('EnumerationSelect',{attrs:{"label":_vm.$t('Over18'),"list":_vm.yesNoEnumList,"rules":[
        () =>
          _vm.profile.EighteenOrOverId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ],"persistent-hint":""},model:{value:(_vm.profile.EighteenOrOverId),callback:function ($$v) {_vm.$set(_vm.profile, "EighteenOrOverId", $$v)},expression:"profile.EighteenOrOverId"}}),_c('EnumerationSelect',{attrs:{"label":_vm.$t('PRCConsent'),"list":_vm.yesNoEnumList,"rules":[
        () =>
          _vm.profile.PRCConsentId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.PRCConsentId),callback:function ($$v) {_vm.$set(_vm.profile, "PRCConsentId", $$v)},expression:"profile.PRCConsentId"}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("MediaConsentHint"))+" ")]),_c('EnumerationSelect',{attrs:{"label":_vm.$t('MediaConsent'),"persistent-hint":"","list":_vm.yesNoEnumList,"rules":[
        () =>
          _vm.profile.MediaConsentId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.MediaConsentId),callback:function ($$v) {_vm.$set(_vm.profile, "MediaConsentId", $$v)},expression:"profile.MediaConsentId"}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("QuotesAndTestimonialsConsentHint"))+" ")]),_c('EnumerationSelect',{attrs:{"label":_vm.$t('QuotesAndTestimonialsConsent'),"persistent-hint":"","list":_vm.yesNoEnumList,"rules":[
        () =>
          _vm.profile.QuotesAndTestimonialConsentId !== _vm.EmptyGuid ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.QuotesAndTestimonialConsentId),callback:function ($$v) {_vm.$set(_vm.profile, "QuotesAndTestimonialConsentId", $$v)},expression:"profile.QuotesAndTestimonialConsentId"}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("AknowledgementBlurb"))+" ")]),_c('strong',[_c('a',{attrs:{"href":_vm.$t('VolunteerPoliciesLink').toString()}},[_vm._v(" "+_vm._s(_vm.$t("VolunteerPolicies")))])]),_c('v-checkbox',{attrs:{"dense":"","label":_vm.$t('AcceptVolunteerSummary'),"persist-hint":"","rules":[
        () =>
          _vm.profile.AcceptVolunteerPolicies === true ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.AcceptVolunteerPolicies),callback:function ($$v) {_vm.$set(_vm.profile, "AcceptVolunteerPolicies", $$v)},expression:"profile.AcceptVolunteerPolicies"}}),_c('strong',[_c('a',{attrs:{"href":_vm.$t('TrainingPolicyLink').toString()}},[_vm._v(_vm._s(_vm.$t("TrainingPolicy")))])]),_c('v-checkbox',{attrs:{"dense":"","label":_vm.$t('AcceptTrainingPolicy'),"rules":[
        () =>
          _vm.profile.AcceptAccessibilityPolicies === true ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.AcceptAccessibilityPolicies),callback:function ($$v) {_vm.$set(_vm.profile, "AcceptAccessibilityPolicies", $$v)},expression:"profile.AcceptAccessibilityPolicies"}}),_c('strong',[_c('a',{attrs:{"href":_vm.$t('HarassmentPolicyLink').toString()}},[_vm._v(_vm._s(_vm.$t("HarassmentPolicy")))])]),_c('v-checkbox',{attrs:{"dense":"","label":_vm.$t('AcceptHarassmentPolicy'),"rules":[
        () =>
          _vm.profile.AcceptHarassmentPolicies === true ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.AcceptHarassmentPolicies),callback:function ($$v) {_vm.$set(_vm.profile, "AcceptHarassmentPolicies", $$v)},expression:"profile.AcceptHarassmentPolicies"}}),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("ConfidentialityAgreement")))])]),_c('v-checkbox',{attrs:{"dense":"","label":_vm.$t('AcceptConfidentialityPolicy'),"rules":[
        () =>
          _vm.profile.AcceptConfidentialityPolicies === true ||
          `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},model:{value:(_vm.profile.AcceptConfidentialityPolicies),callback:function ($$v) {_vm.$set(_vm.profile, "AcceptConfidentialityPolicies", $$v)},expression:"profile.AcceptConfidentialityPolicies"}}),_c('div',[_c('strong',[_c('a',{attrs:{"target":"_blank","href":_vm.$t('CovidBestPracticesAgreementURL').toString()}},[_vm._v(_vm._s(_vm.$t("CovidBestPracticesAgreement")))])])]),_c('v-checkbox',{attrs:{"dense":"","disabled":_vm.covidBestPracticesAccepted,"label":_vm.$t('CovidBestPracticesAgreementLabel')},model:{value:(_vm.profile.AcceptCovidBestPracticesAgreement),callback:function ($$v) {_vm.$set(_vm.profile, "AcceptCovidBestPracticesAgreement", $$v)},expression:"profile.AcceptCovidBestPracticesAgreement"}}),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("CovidVaccinationStatus")))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("CovidVaccinationAgreement"))+" ")]),_c('a',{attrs:{"target":"_blank","href":_vm.$t('CovidVaccinationURL').toString()}},[_vm._v(_vm._s(_vm.$t("CovidVaccinationURLText")))]),_c('v-radio-group',{model:{value:(_vm.profile.CovidVaccinesReceived),callback:function ($$v) {_vm.$set(_vm.profile, "CovidVaccinesReceived", $$v)},expression:"profile.CovidVaccinesReceived"}},[_c('v-radio',{attrs:{"label":_vm.$t('CovidVaccinesReceived'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('CovidVaccinesDeclined'),"value":false}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveClicked}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }