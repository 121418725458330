var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pa-2"},[_c('v-card-text',[_c('v-form',{key:_vm.renderKey,ref:"hlForm"},[(_vm.StaffOrAdmin)?_c('v-switch',{staticClass:"mb-2",attrs:{"id":_vm.id ? `${_vm.id}-non-account-switch` : undefined,"color":"primary","label":_vm.$t('AddNonAccountVolunteer')},on:{"change":_vm.onAuxInputChanged},model:{value:(_vm.nonAccVolunteer),callback:function ($$v) {_vm.nonAccVolunteer=$$v},expression:"nonAccVolunteer"}}):_vm._e(),(!_vm.AuxInput)?[_c('VMSAutoComplete',{attrs:{"id":_vm.id ? `${_vm.id}-lookup` : undefined,"label":_vm.$t('VolunteerLookup'),"placeholder":_vm.$t('AutocompletePlaceholderText'),"rules":[
            () =>
              (_vm.lookupItem && _vm.lookupItem.SubjectId.length > 0) ||
              `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
          ],"data-source":_vm.volunteer_data_source},model:{value:(_vm.lookupItem),callback:function ($$v) {_vm.lookupItem=$$v},expression:"lookupItem"}})]:_vm._e(),(_vm.AuxInput && _vm.logItem.AuxData)?[_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-first-name` : undefined,"label":"First Name","rules":[
            () =>
              (_vm.logItem.AuxData && _vm.logItem.AuxData.FirstName.length > 0) ||
              `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
          ]},model:{value:(_vm.logItem.AuxData.FirstName),callback:function ($$v) {_vm.$set(_vm.logItem.AuxData, "FirstName", $$v)},expression:"logItem.AuxData.FirstName"}}),_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-last-name` : undefined,"label":_vm.$t('LastName'),"rules":[
            () =>
              (_vm.logItem.AuxData && _vm.logItem.AuxData.LastName.length > 0) ||
              `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
          ]},model:{value:(_vm.logItem.AuxData.LastName),callback:function ($$v) {_vm.$set(_vm.logItem.AuxData, "LastName", $$v)},expression:"logItem.AuxData.LastName"}}),_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-email` : undefined,"label":_vm.$t('Email'),"rules":[
            () =>
              (_vm.logItem.AuxData && _vm.logItem.AuxData.Email.length > 0) ||
              `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
          ]},model:{value:(_vm.logItem.AuxData.Email),callback:function ($$v) {_vm.$set(_vm.logItem.AuxData, "Email", $$v)},expression:"logItem.AuxData.Email"}}),_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-org-type` : undefined,"label":_vm.$t('VolunteerOrganizationAffiliation'),"rules":[
            () =>
              (_vm.logItem.AuxData && _vm.logItem.AuxData.Affiliation.length > 0) ||
              `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
          ]},model:{value:(_vm.logItem.AuxData.Affiliation),callback:function ($$v) {_vm.$set(_vm.logItem.AuxData, "Affiliation", $$v)},expression:"logItem.AuxData.Affiliation"}})]:_vm._e(),_vm._l((_vm.hourtypes),function(ht,idx){return _c('VMSTextField',{key:idx,attrs:{"id":_vm.id ? `${_vm.id}-${idx}-hours` : undefined,"label":_vm.$t(ht.Name),"rules":[() => _vm.validateHours()]},model:{value:(_vm.hourModel[ht.Id]),callback:function ($$v) {_vm.$set(_vm.hourModel, ht.Id, _vm._n($$v))},expression:"hourModel[ht.Id]"}})})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t("Save")))]),(!_vm.edit)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveAddNewClick}},[_vm._v(_vm._s(_vm.$t("SaveAndAddAnother")))]):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onCancelClick}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }