
import Vue from "vue";
import { Component } from "vue-property-decorator";
import VolunteerProfileApiService from "../../services/VolunteerProfileApiService";
import Guid from "../../types/Guid";
import VolunteerProfile from "../../types/Profile/VolunteerProfile";
import VolunteerProfileNonStudent from "../../types/Profile/VolunteerProfileNonStudent";
import VolunteerProfileStudent from "../../types/Profile/VolunteerProfileStudent";
import SiteResponse from "@/components/SiteResponse.vue";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import EnumerationResponse from "@/components/EnumerationResponse.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import OrganizationApiService from "@/services/OrganizationApiService";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";
import OrganizationDataSource from "@/types/Pocos/AutoCompleteDataSources/OrganizationDataSource";

@Component({
  components: {
    SiteResponse,
    EnumerationSelect,
    EnumerationResponse,
    PhoneNumberInput,
    VMSTextField,
    VMSTextArea,
    VMSSelect,
    VMSAutoComplete
  }
})
export default class VolunteerProfileView extends Vue {
  student: string = "";
  subjectId: string = this.$VmsLoggedInSubjectId;
  profile: VolunteerProfile = new VolunteerProfile();
  profileApiService: VolunteerProfileApiService = new VolunteerProfileApiService();
  enumApiService: EnumerationApiService = new EnumerationApiService();
  yesNoEnumList: EnumerationList = new EnumerationList();
  languagesEnumList: EnumerationList = new EnumerationList();
  pronounsEnumList: EnumerationList = new EnumerationList();
  provincesEnumList: EnumerationList = new EnumerationList();
  levelsOfStudyEnumList: EnumerationList = new EnumerationList();
  workSituationsEnumList: EnumerationList = new EnumerationList();
  interestedOpportunitiesEnumList: EnumerationList = new EnumerationList();
  areasOfStudyEnumList: EnumerationList = new EnumerationList();
  ethnicDescriptorsEnumList: EnumerationList = new EnumerationList();
  genderEnumList: EnumerationList = new EnumerationList();

  pronounConditional: boolean = false;
  genderConditional: boolean = false;
  covidBestPracticesAccepted: boolean = false;

  psi_type: string | undefined;
  orgApiService: OrganizationApiService = new OrganizationApiService();
  org_data_source: OrganizationDataSource = new OrganizationDataSource();

  get YesNo(): object[] {
    return [
      {
        text: this.$t("Yes"),
        value: "Yes"
      },
      {
        text: this.$t("No"),
        value: "No"
      }
    ];
  }

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  mounted() {
    if (this.$route.query.subjectId) {
      this.subjectId = this.$route.query.subjectId as string;
    }

    this.orgApiService
      .GetEnumerationList("SchoolSubType", this.$i18n.locale)
      .then(resp => {
        const school_types = resp.Value;
        const psi = school_types.find(
          e => e.Description === "Post-Secondary Institution"
        );

        if (psi) {
          this.psi_type = psi.Id;
          this.org_data_source.SetTypeIdFilter(this.psi_type);
        }
      });

    this.enumApiService
      .GetEnumerationListsBySystemName([
        "YesNo",
        "Languages",
        "Pronouns",
        "Provinces",
        "AreasOfStudy",
        "EthnicDescriptors",
        "WorkSituations",
        "LevelsOfStudy",
        "VolunteeringOpportunities",
        "GenderIdentities"
      ])
      .then(resp => {
        let lists: EnumerationList[] = resp.Value;
        this.yesNoEnumList =
          lists.find(x => x.SystemName === "YesNo") || new EnumerationList();
        this.languagesEnumList =
          lists.find(x => x.SystemName === "Languages") ||
          new EnumerationList();
        this.pronounsEnumList =
          lists.find(x => x.SystemName === "Pronouns") || new EnumerationList();
        this.provincesEnumList =
          lists.find(x => x.SystemName === "Provinces") ||
          new EnumerationList();
        this.areasOfStudyEnumList =
          lists.find(x => x.SystemName === "AreasOfStudy") ||
          new EnumerationList();
        this.ethnicDescriptorsEnumList =
          lists.find(x => x.SystemName === "EthnicDescriptors") ||
          new EnumerationList();
        this.workSituationsEnumList =
          lists.find(x => x.SystemName === "WorkSituations") ||
          new EnumerationList();
        this.levelsOfStudyEnumList =
          lists.find(x => x.SystemName === "LevelsOfStudy") ||
          new EnumerationList();
        this.interestedOpportunitiesEnumList =
          lists.find(x => x.SystemName === "VolunteeringOpportunities") ||
          new EnumerationList();
        this.genderEnumList =
          lists.find(x => x.SystemName === "GenderIdentities") ||
          new EnumerationList();

        this.profileApiService
          .GetVolunteerProfileBySubjectId(this.subjectId)
          .then(resp => {
            if (resp.Value) {
              this.profile = resp.Value || new VolunteerProfile();

              //clear stuff
              this.profile.EighteenOrOverId = Guid.Empty;
              this.profile.EighteenOrOver = undefined;
              this.profile.MediaConsentId = Guid.Empty;
              this.profile.MediaConsent = undefined;
              this.profile.QuotesAndTestimonialConsentId = Guid.Empty;
              this.profile.QuotesAndTestimonialConsent = undefined;

              this.profile.AcceptVolunteerPolicies = false;
              this.profile.AcceptAccessibilityPolicies = false;
              this.profile.AcceptHarassmentPolicies = false;
              this.profile.AcceptConfidentialityPolicies = false;

              this.covidBestPracticesAccepted = this.profile.AcceptCovidBestPracticesAgreement;

              this.setSubProfile();
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      });
  }

  setSubProfile() {
    let spModifiedOn: Date | null = this.profile.StudentProfile
      ? new Date(this.profile.StudentProfile.ModifiedOn)
      : null;

    let nspModifiedOn: Date | null = this.profile.NonStudentProfile
      ? new Date(this.profile.NonStudentProfile.ModifiedOn)
      : null;

    if (spModifiedOn && !nspModifiedOn) {
      this.student = "Yes";
    } else if (!spModifiedOn && nspModifiedOn) {
      this.student = "No";
    } else if (spModifiedOn && nspModifiedOn) {
      this.student = spModifiedOn > nspModifiedOn ? "Yes" : "No";
    }
  }

  onCurrentlyStudentInput() {
    if (this.student === "Yes") {
      this.profile.StudentProfile = new VolunteerProfileStudent();
      this.profile.NonStudentProfile = undefined;
    } else {
      this.profile.NonStudentProfile = new VolunteerProfileNonStudent();
      this.profile.StudentProfile = undefined;
    }
  }

  onSaveClicked() {
    let form = this.$refs.ProfileForm as any;
    if (form.validate()) {
      if (this.profile.Id === Guid.Empty) {
        this.profile.SubjectId = this.subjectId;
        this.profileApiService
          .CreateVolunteerProfile(this.profile)
          .then(resp => {
            this.$router.back();
          });
      } else {
        this.profileApiService
          .UpdateVolunteerProfile(this.profile)
          .then(resp => {
            this.$router.back();
          });
      }
    } else {
      this.$ScrollToFirstError();
    }
  }

  onPronounConditional(conditional: boolean) {
    this.pronounConditional = conditional;
    if (!this.pronounConditional) {
      this.profile.CustomPronoun = "";
    }
  }

  onIdentityConditional(conditional: boolean) {
    this.genderConditional = conditional;
    if (!this.genderConditional) {
      this.profile.CustomGenderIdentity = "";
    }
  }
}
