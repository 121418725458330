
import AuthorizationService from "@/services/AuthorizationService";
import VMSEnumerationApiService from "@/services/VMSEnumerationApiService";
import DataObject from "@/types/DataObject";
import VMSEnumeration from "@/types/Enumeration/VMSEnumeration";
import Guid from "@/types/Guid";
import VolunteerHourType from "@/types/Volunteers/VolunteerHourType";
import VolunteerLogItem from "@/types/Volunteers/VolunteerLogItem";
import VolunteerLogItemAuxData from "@/types/Volunteers/VolunteerLogItemAuxData";
import VolunteerLogItemHours from "@/types/Volunteers/VolunteerLogItemHours";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSAutoComplete from "../fields/VMSAutoComplete.vue";
import VolunteerDataSource, {
  VolunteerLookupItem
} from "@/types/Pocos/AutoCompleteDataSources/VolunteerDataSource";
import Subject from "@/types/Subject/Subject";

@Component({
  components: {
    VMSAutoComplete,
    VMSTextField
  }
})
export default class VolunteerLogItemCreate extends Vue {
  @Prop(Object) value!: VolunteerLogItem | undefined;
  @Prop(String) id: string | undefined;
  @Prop(Array) hourtypes: VolunteerHourType[] | undefined;
  @Prop(Boolean) edit: boolean | undefined;

  authService: AuthorizationService = AuthorizationService.getInstance();
  vmsEnumApiService: VMSEnumerationApiService = new VMSEnumerationApiService();
  prepHourTypeEnum: number = 0;
  deliveryHourTypeEnum: number = 0;
  logItem: VolunteerLogItem = this.value || new VolunteerLogItem();
  lookupItem: VolunteerLookupItem = new VolunteerLookupItem();
  hourModel: DataObject = new DataObject();
  renderKey: number = 0;
  nonAccVolunteer: boolean = false;

  volunteer_data_source: VolunteerDataSource = new VolunteerDataSource();

  @Watch("value")
  onValueChanged() {
    this.logItem = this.value || new VolunteerLogItem();

    if (this.logItem.SubjectId && this.logItem.SiteId) {
      let vli: VolunteerLookupItem = new VolunteerLookupItem();
      vli.SubjectId = this.logItem.SubjectId;
      vli.SiteId = this.logItem.SiteId;
      this.lookupItem = vli;
    }

    this.buildHourModel();
  }

  @Watch("hourtypes")
  onHourTypesChanged() {
    this.buildHourModel();
  }

  get Types(): VolunteerHourType[] {
    return this.hourtypes || [];
  }

  get AuxInput(): boolean {
    return (
      this.logItem.AuxData !== null && this.logItem.AuxData.Deleted === false
    );
  }

  get StaffOrAdmin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  mounted() {
    this.buildHourModel();

    if (this.logItem.SubjectId && this.logItem.SiteId) {
      let vli: VolunteerLookupItem = new VolunteerLookupItem();
      vli.SubjectId = this.logItem.SubjectId;
      vli.SiteId = this.logItem.SiteId;
      this.lookupItem = vli;
    }

    this.vmsEnumApiService.GetVMSEnumerations().then(resp => {
      const enums: VMSEnumeration[] = resp.Value;
      const hourTypeEnum: VMSEnumeration =
        enums.find(e => e.Name === "LogHourTypes") || new VMSEnumeration();
      this.prepHourTypeEnum = hourTypeEnum.Items["Preparation"];
      this.deliveryHourTypeEnum = hourTypeEnum.Items["Delivery"];
    });
  }

  buildHourModel() {
    this.hourModel = new DataObject();
    let htypes: VolunteerHourType[] = this.hourtypes || [];
    htypes.forEach((ht: VolunteerHourType) => {
      let itemHours:
        | VolunteerLogItemHours
        | undefined = this.logItem.LogHours.find(x => x.HourTypeId === ht.Id);
      this.hourModel[ht.Id] = itemHours ? itemHours.Hours : 0;
    });

    (this.$refs.hlForm as any).resetValidation();

    // magic!
    ++this.renderKey;
  }

  validateHours(): boolean | string {
    let ret: number = 0;
    for (let idx in this.hourModel) {
      ret += this.hourModel[idx];
    }
    return ret > 0 || (this.$root.$t("HoursValidationMsg") as string);
  }

  onAuxInputChanged() {
    if (this.nonAccVolunteer) {
      this.lookupItem = new VolunteerLookupItem();
      this.logItem.AuxData = new VolunteerLogItemAuxData();
    } else {
      if (this.logItem.AuxData && this.logItem.AuxData.Id !== Guid.Empty) {
        this.logItem.AuxData.Deleted = true;
      } else {
        this.logItem.AuxData = null;
      }
    }
  }

  buildLogItem() {
    if (this.AuxInput === false) {
      this.logItem.SubjectId = this.lookupItem.SubjectId;
      this.logItem.SiteId = this.lookupItem.SiteId;
      this.logItem.Subject = new Subject();
      this.logItem.Subject.Id = this.lookupItem.Subject.Id;
      this.logItem.Subject.SSOId = this.lookupItem.Subject.SSOId;
      this.logItem.Subject.GivenName = this.lookupItem.Subject.GivenName;
      this.logItem.Subject.FamilyName = this.lookupItem.Subject.FamilyName;
      this.logItem.Subject.Email = this.lookupItem.Subject.Email;
    }

    this.logItem.LogHours.forEach((lh: VolunteerLogItemHours) => {
      let hourType: string = lh.HourTypeId || "";
      let hours: number = this.hourModel[hourType];
      lh.Hours = hours;
    });

    this.lookupItem = new VolunteerLookupItem();
    (this.$refs.hlForm as any).resetValidation();
  }

  onSaveClick() {
    if ((this.$refs.hlForm as any).validate()) {
      this.buildLogItem();
      this.$emit("input", this.logItem);
      this.$emit("save");
    }
  }

  onSaveAddNewClick() {
    if ((this.$refs.hlForm as any).validate()) {
      this.buildLogItem();
      this.$emit("input", this.logItem);
      this.$emit("saveAdd");
    }
  }

  onCancelClick() {
    this.lookupItem = new VolunteerLookupItem();
    (this.$refs.hlForm as any).resetValidation();
    this.$emit("cancel");
  }
}
