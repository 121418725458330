import {
  AutoCompleteDataSourceResult,
  IAutoCompleteDataSource
} from "@/components/fields/VMSAutoComplete.vue";
import EducatorAuxDataApiService from "@/services/EducatorAuxDataApiService";
import QueryEducatorsRequest from "@/types/Educator/QueryEducatorsRequest";
import { SortDirection } from "../Pagination/PagedQueryRequest";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import Guid from "@/types/Guid";

export default class EducatorAuxDataDataSource
  implements IAutoCompleteDataSource {
  query: QueryEducatorsRequest = new QueryEducatorsRequest();
  apiService: EducatorAuxDataApiService = new EducatorAuxDataApiService();

  constructor() {
    this.query.Take = 20;
    this.query.SortColumn = "FirstName";
    this.query.Sort = SortDirection.Ascending;
  }

  map(ead: EducatorAuxData): AutoCompleteDataSourceResult {
    return new AutoCompleteDataSourceResult(
      ead,
      `${ead.FirstName} ${ead.LastName} - ${ead.Email}`,
      ead
    );
  }

  async GetByValue(value: any): Promise<AutoCompleteDataSourceResult | null> {
    const educator: EducatorAuxData = value as EducatorAuxData;

    if (!educator || educator.Id === Guid.Empty) {
      return null;
    }

    const resp = await this.apiService.GetEducatorAuxDataById(educator.Id);
    return this.map(resp.Value);
  }

  async Query(search: string): Promise<AutoCompleteDataSourceResult[]> {
    this.query.Search = search;

    const resp = await this.apiService.QueryEducatorAuxData(this.query);

    if (!resp.Success) {
      console.log(resp);
      return [];
    }

    return resp.Value.map((ead: EducatorAuxData) => this.map(ead));
  }
}
