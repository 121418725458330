
import SubjectApiService from "@/services/SubjectApiService";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class VolunteerThankYou extends Vue {
  apiService: SubjectApiService = new SubjectApiService();

  onDloadCertificateClicked() {
    this.apiService.DownloadCertificate(null, null, this.$i18n.locale);
  }
}
