
import AuthorizationService from "@/services/AuthorizationService";
import TrainingApiService from "@/services/TrainingApiService";
import Training from "@/types/Training/Training";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmButton from "../../components/ConfirmButton.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class TrainingList extends Vue {
  /* props: { siteId: s.Id, urlSite: urlSite } */
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  search: string = "";
  training: any[] = [];
  trainingApiService: TrainingApiService = new TrainingApiService();
  siteHostedOnly: boolean = true;
  activeSeasonOnly: boolean = true;
  authService: AuthorizationService = AuthorizationService.getInstance();

  get Headers(): object[] {
    return [
      {
        text: this.$root.$t("Name"),
        align: "left",
        value: "DisplayName"
      },
      {
        text: this.$root.$t("Type"),
        align: "left",
        value: "DisplayType"
      },
      {
        text: this.$root.$t("Date"),
        align: "left",
        value: "TrainingItem.CompletedDate",
        sort: this.$DateSort
      },
      {
        text: "",
        align: "right",
        value: "Actions"
      }
    ];
  }

  get StaffAdmin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  mounted() {
    this.getData();
  }

  getData() {
    this.trainingApiService
      .GetTrainingBySiteId(
        this.siteId || "",
        this.activeSeasonOnly,
        this.siteHostedOnly
      )
      .then(resp => {
        this.training = [];

        this.training = resp.Value.map(t => {
          const td_key = t.TrainingDelivered
            ? this.$t(t.TrainingDelivered.LocalizationKey).toString()
            : "";

          return {
            TrainingItem: t,
            DisplayName: t.OtherTraining ? t.OtherTraining : td_key,
            DisplayType: this.$t(t.TrainingType.Name).toString()
          };
        });
      });
  }

  onFiltersChanged() {
    this.getData();
  }

  onEditItem(item: Training) {
    let typename: string = item.TrainingType ? item.TrainingType.Name : "";
    if (typename) {
      this.$router.push(`/${this.urlSite}/${typename}/Update?id=${item.Id}`);
    }
  }

  onDeleteConfirmed(item: Training) {
    this.trainingApiService.DeleteTraining(item.Id).then(resp => {
      if (resp.Success) {
        this.getData();
      }
    });
  }
}
