import Subject from "@/types/Subject/Subject";
import Role from "@/types/Subject/Role";
import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import BaseApiService from "./BaseApiService";
import Site from "@/types/Site/Site";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import { PagedQueryRequest } from "@/types/Pocos/Pagination/PagedQueryRequest";
import PagedUseCaseResponse from "@/types/PagedUseCaseResponse";

export default class SubjectApiService extends BaseApiService {
  async GetSubjects(): Promise<UseCaseResponse<Subject[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Subject`)
      .then(resp => resp.data as UseCaseResponse<Subject[]>);
  }

  async GetSubjectsBySite(siteId: string): Promise<UseCaseResponse<Subject[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Subject?siteId=${siteId}`)
      .then(resp => resp.data as UseCaseResponse<Subject[]>);
  }

  async SearchSubjects(search: string): Promise<UseCaseResponse<Subject[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Subject?search=${search}`)
      .then(resp => resp.data as UseCaseResponse<Subject[]>);
  }

  async QuerySubjects(
    query: PagedQueryRequest
  ): Promise<PagedUseCaseResponse<any>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Subject/Query`, query)
      .then(resp => resp.data as PagedUseCaseResponse<any>);
  }

  async GetSubjectById(id: string): Promise<UseCaseResponse<Subject>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Subject/${id}`)
      .then(resp => resp.data as UseCaseResponse<Subject>);
  }

  async RefreshSubject(profile: any): Promise<UseCaseResponse<Subject>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Subject/`, profile)
      .then(resp => resp.data as UseCaseResponse<Subject>);
  }

  async GetRoles(): Promise<UseCaseResponse<Role>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/Role/`)
      .then(resp => resp.data as UseCaseResponse<Role>);
  }

  async ApplyRole(
    userId: string,
    roleId: string
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Subject/${userId}/Role/${roleId}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async RevokeRole(
    userId: string,
    roleId: string
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Subject/${userId}/Role/${roleId}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async GetVolunteerRecordBySubjectId(
    id: string
  ): Promise<UseCaseResponse<Subject>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Subject/${id}/VolunteerRecord`)
      .then(resp => resp.data as UseCaseResponse<Subject>);
  }

  // eslint-disable-next-line
  async DownloadCertificate(subject_id: string | null, season_id: string | null, locale: string): Promise<any> {    
    await this.defineHeader();

    let url = `${this.url}/api/vms/Subject/VolunteerCertificate?locale=${locale}`;

    if (subject_id) {
      url += `&subject_id=${subject_id}`;
    }

    if (season_id) {
      url += `&season_id=${season_id}`;
    }

    return axios({
      url: url,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      if (response && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data as any])
        );
        const link = document.createElement("a");
        const f_name =
          locale === "fr" ? "CertificatBenevole" : "VolunteerCertificate";

        link.href = url;
        link.setAttribute("download", `${f_name}.pdf`);
        document.body.appendChild(link);
        link.click();
        return true;
      } else {
        return false;
      }
    });
  }
}
