var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"id":_vm.id ? `${_vm.id}-add` : undefined,"disabled":_vm.disabled ||
              (_vm.maxSchoolsCount > 0 && _vm.Items.length >= _vm.maxSchoolsCount)},on:{"click":_vm.onAddRecordClicked}},on),[_vm._v("mdi-plus-box")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Add")))])])],1),_c('v-data-table',{key:_vm.tableRenderKey,staticClass:"elevation-1 mt-2",attrs:{"id":_vm.id ? `${_vm.id}-table` : undefined,"items":_vm.Items,"headers":_vm.headers,"hide-default-footer":_vm.Items.length <= 10},scopedSlots:_vm._u([{key:`item.Actions`,fn:function({ item }){return [(!_vm.disabled)?_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"id":_vm.id ? `${_vm.id}-edit` : undefined},on:{"click":function($event){return _vm.onEditClicked(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Edit")))])]),_c('ConfirmButton',{attrs:{"id":_vm.id ? `${_vm.id}-delete` : undefined,"tooltip":_vm.$t('Delete'),"icon":"mdi-delete"},on:{"confirmed":function($event){return _vm.onDeleteConfirmed(item)}}},[_vm._v(" "+_vm._s(_vm.$t("Delete"))+" "+_vm._s(_vm.$t("Data"))+"? ")])],1):_vm._e()]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.schoolDemoDialog),callback:function ($$v) {_vm.schoolDemoDialog=$$v},expression:"schoolDemoDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_c('v-form',{ref:"schoolDemoForm"},[_c('v-switch',{attrs:{"id":_vm.id ? `${_vm.id}-new-org-switch` : undefined,"label":_vm.$t('CreateNewSchoolOrganization')},on:{"change":_vm.onAddOrgChange},model:{value:(_vm.addOrg),callback:function ($$v) {_vm.addOrg=$$v},expression:"addOrg"}}),(!_vm.addOrg)?[_c('VMSAutoComplete',{attrs:{"id":_vm.id ? `${_vm.id}-school-lookup` : undefined,"placeholder":_vm.$t('AutocompletePlaceholderText'),"label":_vm.$t('SchoolOrganization'),"rules":[
                () =>
                  (_vm.item.OrganizationId && _vm.item.OrganizationId.length > 0) ||
                  _vm.$t('FieldIsRequired')
              ],"data-source":_vm.org_data_source},on:{"input-data":function($event){_vm.item.Organization = $event}},model:{value:(_vm.item.OrganizationId),callback:function ($$v) {_vm.$set(_vm.item, "OrganizationId", $$v)},expression:"item.OrganizationId"}})]:[_c('SchoolEdit',{attrs:{"id":_vm.id ? `${_vm.id}-new` : undefined},model:{value:(_vm.editOrg),callback:function ($$v) {_vm.editOrg=$$v},expression:"editOrg"}})],(!_vm.fieldHidden('NumberOfYouth'))?_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-num-youth` : undefined,"label":_vm.$t('NumberOfYouth'),"hint":_vm.$t('NumberOfYouthHint'),"rules":[
              () =>
                (_vm.item.NumberOfYouth && _vm.item.NumberOfYouth >= 0) ||
                `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
            ]},model:{value:(_vm.item.NumberOfYouth),callback:function ($$v) {_vm.$set(_vm.item, "NumberOfYouth", $$v)},expression:"item.NumberOfYouth"}}):_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":_vm.id ? `${_vm.id}-save` : undefined,"color":"primary"},on:{"click":function($event){return _vm.onSaveItemClicked(false)}}},[_vm._v(_vm._s(_vm.$t("Save")))]),(
            !_vm.edit &&
              (_vm.maxSchoolsCount == 0 || _vm.Items.length + 1 < _vm.maxSchoolsCount)
          )?_c('v-btn',{attrs:{"id":_vm.id ? `${_vm.id}-save-add` : undefined,"color":"primary"},on:{"click":function($event){return _vm.onSaveItemClicked(true)}}},[_vm._v(_vm._s(_vm.$t("SaveAndAddAnother"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"id":_vm.id ? `${_vm.id}-cancel` : undefined,"color":"primary"},on:{"click":_vm.onCancelClick}},[_vm._v(_vm._s(_vm.$t("Cancel"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }