import {
  AutoCompleteDataSourceResult,
  IAutoCompleteDataSource
} from "@/components/fields/VMSAutoComplete.vue";
import SubjectApiService from "@/services/SubjectApiService";
import Site from "@/types/Site/Site";
import Subject from "@/types/Subject/Subject";
import {
  PagedQueryRequest,
  SortDirection
} from "../Pagination/PagedQueryRequest";

export class VolunteerLookupItem {
  SubjectId: string = "";
  SiteId: string = "";
  SubjectName: string = "";
  SiteName: string = "";

  Subject: any | null = null;
}

export default class VolunteerDataSource implements IAutoCompleteDataSource {
  api_service: SubjectApiService = new SubjectApiService();
  query: PagedQueryRequest = new PagedQueryRequest();

  constructor() {
    this.query.Take = 20;
    this.query.SortColumn = "FamilyName";
    this.query.Sort = SortDirection.Ascending;
  }

  async Query(search: string): Promise<AutoCompleteDataSourceResult[]> {
    let ret: AutoCompleteDataSourceResult[] = [];

    this.query.Search = search;
    const resp = await this.api_service.QuerySubjects(this.query);

    if (resp.Success) {
      console.log(resp);
      ret = resp.Value.flatMap(sub =>
        sub.Sites.map((sr: any) => {
          const v: VolunteerLookupItem = new VolunteerLookupItem();
          v.SiteId = sr.Id;
          v.SiteName = sr.Name;
          v.SubjectId = sub.Id;
          v.SubjectName = `${sub.GivenName} ${sub.FamilyName}`;
          v.Subject = sub;

          return new AutoCompleteDataSourceResult(
            v,
            `${v.SubjectName} - ${v.SiteName}`,
            v
          );
        })
      );

      console.log(ret);
    }

    return ret;
  }

  async GetByValue(value: any): Promise<AutoCompleteDataSourceResult | null> {
    let ret: AutoCompleteDataSourceResult | null = null;

    const lookupItem: VolunteerLookupItem = value as VolunteerLookupItem;

    if (lookupItem.SubjectId) {
      const resp = await this.api_service.GetSubjectById(lookupItem.SubjectId);
      const sub: Subject = resp.Value;

      let subjectSites = sub.SiteRegistrations.map(sr => {
        let site: Site = sr.Site || new Site();
        return {
          Id: `${sub.Id}${site.Id}`,
          Subject: sub,
          Site: site
        };
      });

      const found = subjectSites.find(
        ss => ss.Id === `${lookupItem.SubjectId}${lookupItem.SiteId}`
      );

      if (found) {
        const vli: VolunteerLookupItem = new VolunteerLookupItem();
        return new AutoCompleteDataSourceResult(
          vli,
          `${found.Subject.FullName} - ${found.Site.Name}`,
          vli
        );
      }
    }

    return ret;
  }
}
