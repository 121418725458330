
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import EnumerationResponse from "../EnumerationResponse.vue";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationApiService from "@/services/EnumerationApiService";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import SchoolEdit from "@/components/school/SchoolEdit.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import Guid from "@/types/Guid";
import VMSAutoComplete from "../fields/VMSAutoComplete.vue";
import OrganizationDataSource from "@/types/Pocos/AutoCompleteDataSources/OrganizationDataSource";

@Component({
  components: {
    VMSAutoComplete,
    EnumerationResponse,
    PhoneNumberInput,
    SchoolEdit,
    VMSTextField
  }
})
export default class EducatorEdit extends Vue {
  @Prop(Object) value: EducatorAuxData | undefined;
  @Prop(String) id: string | undefined;

  educator: EducatorAuxData = new EducatorAuxData();
  enumApiService: EnumerationApiService = new EnumerationApiService();
  gradesEnumList: EnumerationList = new EnumerationList();
  languagesEnumList: EnumerationList = new EnumerationList();

  org_data_source: OrganizationDataSource = new OrganizationDataSource();
  new_org: boolean = false;

  mounted() {
    this.educator = this.value || new EducatorAuxData();

    this.enumApiService
      .GetEnumerationListsBySystemName(["Languages", "Grades"])
      .then(resp => {
        let lists: EnumerationList[] = resp.Value;
        console.log(lists);
        this.languagesEnumList =
          lists.find(l => l.SystemName === "Languages") ||
          new EnumerationList();
        this.gradesEnumList =
          lists.find(l => l.SystemName === "Grades") || new EnumerationList();
      });
  }

  onAddOrgChanged() {
    if (this.new_org) {
      this.educator.Organization = new UpdateOrganizationRequest();
      this.educator.Organization.Id = Guid.Empty;
      this.educator.OrganizationId = Guid.Empty;
    } else {
      this.educator.Organization = undefined;
      this.educator.OrganizationId = "";
    }
  }

  validateEmail(email: string): boolean | string {
    const regex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
    return regex.test(email) || this.$t("InvalidEmailValidationMsg").toString();
  }

  @Watch("value")
  onValueChanged() {
    this.educator = this.value || new EducatorAuxData();
  }
}
