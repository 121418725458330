
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmButton from "@/components/ConfirmButton.vue";

@Component({
  components: {
    ConfirmButton
  }
})
export default class WorkshopListTile extends Vue {
  @Prop(Object) value!: any;

  seeAll: boolean = false;
  seeAllEducators: boolean = false;

  get SeasonLocked(): boolean {
    return this.value.Season!.Locked;
  }

  get Volunteers(): string {
    if (!this.value.Volunteers || this.value.Volunteers.length === 0) return "";

    return this.seeAll
      ? this.value.Volunteers.join(", ")
      : `${this.value.Volunteers[0]}...`;
  }

  get Educators(): string {
    if (!this.value.Educators || this.value.Educators.length === 0) return "";

    return this.seeAllEducators
      ? this.value.Educators.join(", ")
      : `${this.value.Educators[0]}...`;
  }

  onEvalItem() {
    this.$emit("eval", this.value.Id);
  }

  onEditClicked() {
    this.$emit("edit", this.value);
  }

  onDeleteConfirmed() {
    this.$emit("delete", this.value);
  }
}
