import { render, staticRenderFns } from "./WorkshopListTile.vue?vue&type=template&id=297902ce&scoped=true&"
import script from "./WorkshopListTile.vue?vue&type=script&lang=ts&"
export * from "./WorkshopListTile.vue?vue&type=script&lang=ts&"
import style0 from "./WorkshopListTile.vue?vue&type=style&index=0&id=297902ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297902ce",
  null
  
)

export default component.exports