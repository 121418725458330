import {
  AutoCompleteDataSourceResult,
  IAutoCompleteDataSource
} from "@/components/fields/VMSAutoComplete.vue";
import SubjectApiService from "@/services/SubjectApiService";
import {
  PagedQueryRequest,
  SortDirection
} from "../Pagination/PagedQueryRequest";
import Guid from "@/types/Guid";

export default class SubjectDataSource implements IAutoCompleteDataSource {
  api_service: SubjectApiService = new SubjectApiService();
  query: PagedQueryRequest = new PagedQueryRequest();

  constructor() {
    this.query.Take = 20;
    this.query.SortColumn = "FamilyName";
    this.query.Sort = SortDirection.Ascending;
  }

  async Query(search: string): Promise<AutoCompleteDataSourceResult[]> {
    let ret: AutoCompleteDataSourceResult[] = [];

    this.query.Search = search;
    const resp = await this.api_service.QuerySubjects(this.query);

    if (resp.Success) {
      console.log(resp);
      ret = resp.Value.map(
        sub =>
          new AutoCompleteDataSourceResult(
            sub,
            `${sub.GivenName} ${sub.FamilyName}`,
            sub.Id
          )
      );
    }

    return ret;
  }

  async GetByValue(value: any): Promise<AutoCompleteDataSourceResult | null> {
    if (!value || value === Guid.Empty) {
      return null;
    }

    const resp = await this.api_service.GetSubjectById(value);
    if (!resp.Success) {
      return null;
    } else {
      const sub = resp.Value;
      return new AutoCompleteDataSourceResult(
        sub,
        `${sub.GivenName} ${sub.FamilyName}`,
        sub.Id
      );
    }
  }
}
