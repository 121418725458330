var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t(_vm.HeaderText)))]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-form',{ref:"form"},[(!_vm.edit)?_c('v-switch',{attrs:{"label":_vm.$t('AddNewEducator')},on:{"change":_vm.onAddEducatorChanged},model:{value:(_vm.addEducator),callback:function ($$v) {_vm.addEducator=$$v},expression:"addEducator"}}):_vm._e(),(!_vm.edit && !_vm.addEducator)?_c('VMSAutoComplete',{attrs:{"label":_vm.$t('FindExistingEducator'),"placeholder":_vm.$t('AutocompletePlaceholderText'),"rules":[
          () =>
            (_vm.educator &&
              _vm.educator.Id.length > 0 &&
              _vm.educator.Id !== _vm.EmptyGuid) ||
            _vm.$t('FieldIsRequired')
        ],"data-source":_vm.educator_data_source},model:{value:(_vm.educator),callback:function ($$v) {_vm.educator=$$v},expression:"educator"}}):_vm._e(),(_vm.addEducator || _vm.edit)?[_c('EducatorEdit',{model:{value:(_vm.educator),callback:function ($$v) {_vm.educator=$$v},expression:"educator"}})]:_vm._e()],2),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveClicked}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }