import Enumeration from "@/types/Enumeration/Enumeration";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import VMSEnumeration from "@/types/Enumeration/VMSEnumeration";
import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import BaseApiService from "./BaseApiService";

export default class EnumerationApiService extends BaseApiService {
  async GetEnumerations(): Promise<UseCaseResponse<Enumeration[]>> {
    await this.defineHeader();
    return axios
      .get(this.url + "/api/vms/Enumeration")
      .then(resp => resp.data as UseCaseResponse<Enumeration[]>);
  }

  async GetEnumerationById(id: string): Promise<UseCaseResponse<Enumeration>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Enumeration/${id}`)
      .then(resp => resp.data as UseCaseResponse<Enumeration>);
  }

  async CreateEnumeration(
    item: Enumeration
  ): Promise<UseCaseResponse<Enumeration>> {
    await this.defineHeader();
    return axios
      .post(this.url + "/api/vms/Enumeration", item)
      .then(resp => resp.data as UseCaseResponse<Enumeration>);
  }

  async UpdateEnumeration(
    item: Enumeration
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Enumeration/${item.Id}`, item)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteEnumeration(
    item: Enumeration
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Enumeration/${item.Id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async CreateEnumerationList(
    item: EnumerationList
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/EnumerationList`, item)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetEnumerationLists(): Promise<UseCaseResponse<EnumerationList[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EnumerationList`)
      .then(resp => resp.data as UseCaseResponse<EnumerationList[]>);
  }

  async GetEnumerationListsBySystemName(
    systemNames: string[]
  ): Promise<UseCaseResponse<EnumerationList[]>> {
    await this.defineHeader();
    let param: string = `systemNames=${systemNames.join(`&systemNames=`)}`;
    return axios
      .get(`${this.url}/api/vms/EnumerationList?${param}`)
      .then(resp => resp.data as UseCaseResponse<EnumerationList[]>);
  }

  async GetEnumerationList(
    id: string
  ): Promise<UseCaseResponse<EnumerationList>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EnumerationList/${id}`)
      .then(resp => resp.data as UseCaseResponse<EnumerationList>);
  }

  async DeleteEnumerationList(
    item: EnumerationList
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/EnumerationList/${item.Id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async UpdateEnumerationList(
    item: EnumerationList
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/EnumerationList/${item.Id}`, item)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
