import {
  AutoCompleteDataSourceResult,
  IAutoCompleteDataSource
} from "@/components/fields/VMSAutoComplete.vue";
import {
  PagedQueryRequestFiltered,
  SortDirection
} from "../Pagination/PagedQueryRequest";
import OrganizationApiService from "@/services/OrganizationApiService";
import QueryOrganizationsRequest from "@/types/Organization/QueryOrganizationsRequest";
import Guid from "@/types/Guid";

export default class OrganizationDataSource implements IAutoCompleteDataSource {
  apiService: OrganizationApiService = new OrganizationApiService();
  query: PagedQueryRequestFiltered<
    QueryOrganizationsRequest
  > = new PagedQueryRequestFiltered<QueryOrganizationsRequest>(
    new QueryOrganizationsRequest()
  );

  constructor() {
    this.query.Take = 20;
    this.query.SortColumn = "Name";
    this.query.Sort = SortDirection.Ascending;
    this.query.Filter.Status = [0, 1];
  }

  SetTypeIdFilter(type_id: string | null) {
    this.query.Filter.TypeId = type_id;
  }

  async Query(search: string): Promise<AutoCompleteDataSourceResult[]> {
    let ret: AutoCompleteDataSourceResult[] = [];

    this.query.Search = search;
    const resp = await this.apiService.QueryOrganizations(this.query);
    console.log(resp);
    ret = resp.Value.map(o => {
      return new AutoCompleteDataSourceResult(o, `${o.Name} - ${o.City}`, o.Id);
    });

    return ret;
  }

  async GetByValue(value: any): Promise<AutoCompleteDataSourceResult | null> {
    let ret: AutoCompleteDataSourceResult | null = null;

    if (!value || value === Guid.Empty) {
      return ret;
    }

    const resp = await this.apiService.GetOrganizationById(value);
    if (!resp.Success) {
      return null;
    }

    const org = resp.Value;

    return new AutoCompleteDataSourceResult(
      org,
      `${org.Name} - ${org.City}`,
      org.Id
    );
  }
}
