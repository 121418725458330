import {
  AutoCompleteDataSourceResult,
  IAutoCompleteDataSource
} from "@/components/fields/VMSAutoComplete.vue";

export default class ArrayDataSource<T> implements IAutoCompleteDataSource {
  private data: T[] = [];
  private stringify: (item: T) => string;
  private to_val: (item: T) => any;

  constructor(to_display_str: (item: T) => string, to_value: (item: T) => any) {
    this.stringify = to_display_str;
    this.to_val = to_value;
  }

  Load(d: T[]) {
    this.data = d;
  }

  async Query(search: string): Promise<AutoCompleteDataSourceResult[]> {
    let ret: AutoCompleteDataSourceResult[] = [];

    const l_search = search ? search.toLowerCase() : "";

    if (l_search.length === 0) {
      return ret;
    }

    const results = this.data.filter(d =>
      this.stringify(d)
        .toLowerCase()
        .includes(l_search)
    );

    ret = results.map(
      r =>
        new AutoCompleteDataSourceResult(r, this.stringify(r), this.to_val(r))
    );

    return ret;
  }

  async GetByValue(value: any): Promise<AutoCompleteDataSourceResult | null> {
    const found = this.data.find(d => this.to_val(d) === value);
    if (!found) {
      return null;
    }

    return new AutoCompleteDataSourceResult(
      found,
      this.stringify(found),
      this.to_val(found)
    );
  }
}
